import { Params } from '@angular/router';
import { CellClickEvent } from '@progress/kendo-angular-grid';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { PageSizeItem, SelectableSettings } from '@progress/kendo-angular-treelist';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { Observable } from 'rxjs';
import { IndvAddresses } from 'src/app/components/members/openapi';
import { CurOrderStatus, OrderItemHcpc, OrderSchedule, Transition } from 'src/app/components/orders/openapi';
import { LobTimeliness } from 'src/app/components/payers/openapi';
import {
  BaseFeeSchedsWithVer,
  BaseFeeScheduleVers,
  FeeSchedCatg,
  FeeSchedEquipType,
  FeeSchedHcpc,
  PayerFeeSchedsWithVer,
  PayerFeeScheduleVer,
  ProvFeeSchedOrg,
  ProvFeeSchedsWithVer,
  ProvFeeSchedVer
} from '../../components/fee-schedules/openapi';
import { ClaimRuleCodes, ClaimRulePayers } from '../../components/mcq/openapi';
import { ListItemDtl, ListItemDtlAttr, ListItemDtlCtx } from '../../components/mdm/openapi';
import { PermissibleItem } from '../shared-access-module/services/access-control.service';
import { FormFieldConfig } from '../shared-components/form-field/form-field.interface';
import { PrismFileInfo } from '../shared-components/upload/upload.component';

export interface IntegraSearchConfigColumnOption {
  key: string;
  label: string;
}

export enum IntegraSearchOperators {
  'equals' = '=',
  'in' = 'in',
  'like' = 'ilike',
  'likebasic' = 'like',
  greaterThanOrEqualTo = '>=',
  greaterThan = '>',
  lessThanOrEqualTo = '<=',
}

export enum DoriSearchOperators {
  'equals' = '=',
  'in' = 'in',
  'like' = 'like'
}

export type SearchOperators = DoriSearchOperators | IntegraSearchOperators;


export interface IntegraSearchConfigColumn {
  type: string;
  value: any;
  initialValue?: any;
  label: string;
  key: string;
  property: string;
  operator?: SearchOperators;
  disableOnSelected?: string[];
  mask?: string;
  group?: number;
  error?: string;
  dateTime?: boolean;
  endOfDay?: boolean;
  placeholder?: string;
  direction?: 'row' | 'column';
  options?: IntegraSearchConfigColumnOption[];
  hide?: boolean;
  disabled?: boolean;
  listTypeName?: string;
  listTypeCtx?: string;
  includeWithAttributes?: Array<{ attribute: string, value: any }>;
  info?: string;
  data?: any;
  config?: any;
  minLength?: number;
  filterable?: boolean;
  pagingConfig?: PagingConfig;

  callback?(searchString: string): Observable<Array<SearchDataList>>;
  fetchSingleItem?(entitiyId: any): Observable<SearchDataList>;
}

export interface IntegraSearchConfigRow {
  columns: IntegraSearchConfigColumn[];
  forceSize?: number;
}

export interface IntegraSearchFilterChanged {
  columnKey: string;
  fieldValue: any;
}

export interface IntegraSearchFilterChanged {
  columnKey: string;
  fieldValue: any;
}

export interface PayersFormValues {
  payerFamilyName: string;
  status: string;
  domains: string[];
  activeFromDt: Date;
  activeToDt: Date;
  createdTs?: string;
  createdBy?: string;
  modifiedTs?: string;
  modifiedBy?: string;
}

export interface PayerPlanFormValues {
  status: string;
  payerPlanId?: number;
  payerPlanName: string;
  activeFromDt: Date;
  activeToDt?: Date;
}

export interface PayerAcctsFormVals {
  payerAcctId?: number;
  payerFamId?: number;
  payerPLanId?: number;
  payerAccountName: string;
  activeFromDt: Date;
  activeToDt: Date;
  status?: string;
  createdTs?: string;
  createdBy?: string;
  modifiedTs?: string;
  modifiedBy?: string;
}

export interface ModalAction {
  text: string;
  icon?: string;
  type?: string;
  disabled?: boolean;
  nativeType?: ButtonNativeType;

  callback<T>(arg?: T): any;
}

export interface IndvAddressesShared {
  id: number;
  type: string;
  name: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  selected?: boolean;
  useAsShipping: boolean;
  relToIndv: string;
  source: DataSource;
  effectiveFromDt?: string;
  effectiveToDt?: string;
  addrBasedOnTypeCode?: string;
  showAddressType?: boolean;
}

export interface ContactCardData {
  cntctName?: string;
  cntctTypeCode: string;
  cntctValue?: string;
  cntctDtls?: Array<ContactDtlData>;
  cntctInfoTypes: Array<ListItemDtl>;
  cntctTypes?: Array<ListItemDtl>;
  isDefault: boolean;
  source: DataSource;
  relToIndv?: string;
}

export interface ContactDtlData {
  cntctDtlTypeCode: string;
  cntctDtlValue: string;
}

export enum DataSource {
  'MEMBER',
  'PAYER',
  'LOB',
  'FACILITY'
}

export interface IndvContactsShared {
  id?: number;
  isDefault: boolean;
  contactTypeCode: string;
  contactValue: string;
  contactIndvName?: string;
  relToIndv: string;
}

export interface PayerTreeSelection {
  payerFamilyId: number;
  payerPlanId: number;
  payerAccountId?: number;
  payerLobId?: number;
  items: any[];
  canAddNewMember?: boolean;
}

export interface Member {
  memberId: number;
  payerLobId: string;
  medicareId: string;
  medicaidId: string;
  firstName: string;
  lastName: string;
  dob: string;
  primaryContact?: string;
}

export interface PayersFilter {
  type: string;
  text: string;
  payerFamilyId?: number;
  payerPlanId?: number;
  payerAccountId?: number;
  payerLobId?: number;
  items?: PayersFilter[];
  value?: boolean;
  canAddNewMember?: boolean;
}

export interface MenuItem extends PermissibleItem {
  text: string;
  icon?: string;
  path?: string;
  expanded?: boolean;
  collapsedItem?: {
    text: string;
    header: boolean;
    items: UserMenuItem[]
  }[];
  children: boolean;
  selected: boolean;
  items?: MenuItem[];
  level?: number;
  isCustom?: boolean;
  featureFlag?: string;
}

export interface ContextMenuItem {
  text: string;
  action: string;
  disabled?: boolean;
}

export interface DrawerConfig {
  mode: string;
  isExpanded: boolean;
  autoCollapse: boolean;
}

export interface UserMenuItem {
  text: string;
  class?: string;
  action?: string;
  items?: UserMenuItem[];
}

export interface CheckedEvent {
  target: {
    checked: boolean;
  };
}

export type ButtonNativeType = 'submit' | 'reset' | 'button';

export interface SectionHeaderAction {
  text: string;
  type: string;
  icon?: string;
  iconImageUrl?: string;
  disabled?: boolean;
  hidden?: boolean;
  widgetId?: string;
  origin?: string;
  route?: string;
  accessId?: string;
  dropDownData?: DropDownButtonData[];
  nativeType?: ButtonNativeType;

  callback<T>(arg?: T): any;
}

export interface MdmDrivenHeaderAction {
  text: string;
  type: string;
  icon: string;
  disabled?: boolean;
  hidden?: boolean;
  route?: string;
  accessId?: string;
  actionId: string;
  nativeType?: ButtonNativeType;
  dropDownData?: DropDownButtonData[];
}

export interface GridData<T> {
  data: Array<T>;
  total: number;
}

export interface GridNoData {
  title?: string;
  description?: string;
  image: string;
}

export interface GridColumnBase {
  field: string;
  fieldAccessor?: string;
  title: string;
  type: string;
  width?: number;
  widthStyle?: {
    width: string;
    bold?: string;
    cellBackground?: string;
  };
  cellDataColor?: string;
  getFieldTypeFromDataItem?: (arg?: any) => string;
  additionalInput?: any;
}

export interface RouterLinkGridColumn extends GridColumnBase {
  type: 'dynamic-router-link';
  getRoute(data: any): string[];
  getDisplay(data: any): string;
  getQueryParams?: (data: any) => Params;
}

export type GridColumn = GridColumnBase | RouterLinkGridColumn;

export interface GridSortOptions {
  mode?: string;
  allowUnsort?: boolean;
  initialDirection?: string;
}

export interface GridPageChangeEvent {
  skip: number;
  take: number;
  totalRows?: number;
}

export interface GridSortDescriptor {
  field: string;
  dir: string;
}

export interface GridIconLabel {
  iconClasses: string; // example: 'first second'
  label?: string;
}

export interface GridValueWithTooltip {
  value: any; // example: 'string value'
  tooltip?: string;
}

export interface ParsedListItem {
  listTypeId?: number;
  listTypeName: string;
  listTypeDesc: string;
  listTypeStartDt: string;
  isSysList?: string;
  isRoList?: string;
}

export interface EntryItemResponse {
  restartRowNum: string;
  items: ParsedEntryItem[];
}

export interface ParsedEntryItem {
  activeFromTo: string;
  listDtlCode: string;
  listDtlDesc: string;
  status: boolean;

  [x: string]: any;

  listDtlId?: number;
}

export interface ParsedProductItem {
  productId: number;
  description: string;
  hcpcCode: string;
  isCommentReq: boolean;
  isEnabled: boolean;
}

export interface ListItemLookup {
  listTypeName: string;
  listTypeCode: string;
  listTypeDesc: string;
  attributes: Map<string, string>;
}

export interface FacilityForOrder {
  organizationId: number;
  facilityId: number;
  organizationLegalName: string;
  organizationDBAName: string;
  storeName: string;
  phoneNumber: string;
  isHistProv: boolean;
  languages: string;
  address: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export enum ParsedPayerType {
  FAMILY = 'family',
  ACCOUNT = 'account',
  PLAN = 'plan',
  LOB = 'lob'
}

export interface ParsedPayer {
  payerId: number;
  payerPlanId?: number;
  payerFamId?: number;
  payerAcctId?: number;
  payerLobId?: number;
  payerName: string;
  payerStatus: string;
  activeDate: string;
  totalMbrshp?: string;
  marketShare?: string;
  benefitType?: string | string[];
  typeOfRelation?: string;
  payerType: ParsedPayerType;
  isExpanded: boolean;
  hasChildren: boolean;
  children?: any[];
}

export interface Stepper {
  steps: StepperStep[];
  current: number;
  width: number;
}

export interface StepperStep {
  label: string;
  icon: string;
  disabled?: boolean;
  path?: string;
}

export interface ProviderOrderState {
  facilityId: number;
  organizationDtls: OrgDetails;

  selectForMe?: boolean;
  acceptedTs?: Date;
  assignedTs?: Date;
}

export interface OrgDetails {
  organizationName: string;
  organizationId: number;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  phoneNumber: string;
}

export interface OrderState {
  stepsCompleted: number[];
  orderSummary: OrderSummaryState;
  memberOrderState: MemberOrderState;
  productOrderState: ProductOrderState;
  providerOrderState: ProviderOrderState;
  extraOrderState: ExtraOrderState;
}

export interface OrderStatusDetails extends CurOrderStatus {
  hasDetails: boolean;
}

export interface OrderSummaryState {
  orderNum: string;
  altSysId?: string | null;
  orderId: number;
  memberId: number;
  payerLobId: number;
  orderStatusCode: string;
  orderStatusDesc: string;
  payerFamilyName: string;
  payerPlanName: string;
  payerAcctName: string;
  payerLobName: string;
  createdTs: Date;
  createdBy: string;
  modifiedTs?: Date;
  modifiedBy?: string;
  orderStatusDetails?: OrderStatusDetails;
  systemType?: string;
  orderTemplate?: OrderTemplate;
}

export interface OrderTemplate {
  tplOrderNum?: string | null;
  tplProviderFacId?: number | null;
  tplProviderOrgId?: number | null;
}

export interface MbrOrderReference {
  mbrId: number;
  payerLobId: number;
  payerFamId: number;
  firstName: string;
  lastName: string;
  dob: string;
  primaryLangCode?: string;
  primaryLangDesc?: string;
  secondaryLangCode?: string;
  secondaryLangDesc?: string;
  insuranceNum: string;
  medicareId?: string;
  medicaidId?: string;
  contacts?: Array<MbrOrderCntctReference>;
  addresses: Array<MbrOrderAddrReference>;
  careNavUser?: CareNavUserReference;
  mbrStartDt: Date;
  mbrEndDt?: Date;
}

export interface MbrOrderCntctReference {
  indvContactId?: number;
  contactTypeCode: string;
  contactTypeDesc: string;
  contactValue: string;
  primaryCntct: boolean;
  relToIndv: string;
  contactIndvName?: string | null;

}

export interface MbrOrderAddrReference {
  indvAddressId?: number;
  addresseeName?: string | null;
  relToIndv: string;
  addressTypeCode: string;
  addressTypeDesc: string;
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  state: string;
  zip: string;
  useAsShipping: boolean;
  effectiveFromDt: string;
  effectiveToDt?: string | null;
}

export interface CareNavUserReference {
  notRequired: boolean;
  careNavUserId: number;
  displayName: string;
}

export interface MemberOrderState {
  address?: IndvAddresses;
  referral?: MemberOrderStateReferral;
  careNavUser?: CareNavUserReference;
}

export interface ProductOrderAuthData {
  icdCodes: Array<string>;
  authorizationCode: string;
  authWindowFrom: Date;
  authWindowTo: Date;
  isAuthCodeReq?: boolean;
  schedFreqCode?: string;
}

export interface ProductOrderState extends ProductOrderAuthData {
  products: Array<ProductsAddedList>;
  orderSchedule?: OrderSchedule;
  transId?: number;
  isMigrated?: boolean;
}

export interface ExtraOrderState {
  isMedicarePrimOrder: boolean;
  expOrderDeliveryDate: Date;
  isSigRequired?: boolean;
  deliveryMethodCode?: string;
  deliveryInstructions?: string;
  additionalComments?: string;
  filesList?: Array<PrismFileInfo>;
  isTrans: boolean;
  transDesc?: string;
  lastServiceDt: Date;
  isMaskedAttachment: boolean;
  isMaskedProvider?: boolean;
  isMaskedEDD?: boolean;
}

export interface OrderAttach {
  orderAttachId?: number;
  fileName: string;
  fileSize: number;
  uploadedTs?: string;
  uploadedBy?: string;
}

export interface MemberOrderStateReferral {
  referralFirstName: string;
  referralLastName: string;
  npi: number;
  phoneNumber: string;
  faxNumber: string;
}

export interface ProductsAddedListModifier {
  listDtlCode: string;
}

export interface ProductsAddedList {
  lineNum: number;
  itemCode: string;
  productId: number;
  altLineId?: string | null;
  productName: string;
  modifiers?: ProductsAddedListModifier[];
  quantity: number;
  isAuthCodeReq?: boolean;
  authorizationCode?: string;
  authWindowFrom?: Date;
  authWindowTo?: Date;
  icdCode?: string;
  isCommentReq?: boolean;
  comments?: string;
  schedFreqCode?: string;
  hcpcs?: OrderItemHcpc[];
  isMiscCode?: boolean;
  transPrevSvcDt?: Date;
  transPrevSvcProvider?: string | null;
  hcpcTransInd?: string | null;
  transPriority?: number | null;
  legacyAuthCode?: string | null;
}

export interface PayerLobFormValues {
  payerLobId?: number;
  payerFamId?: number;
  payerAcctId?: number;
  payerFamName?: string;
  payerLobName: string;
  payerRelationCode: string;
  orderReceiptMethodCode: string;
  lobStatusCode: string;
  benefitTypeCode: string;
  activeFromDt?: Date;
  activeToDt?: Date;
  altSysID?: string;
  payerAdditionalInfo?: string;
  contacts: Array<any>;
  addresses: Array<any>;
  ormEnabled: boolean;
  clmEnabled: boolean;
  lobTimeliness?: Array<LobTimeliness>;
}

export interface OrderNotes {
  noteId: number;
  note: string;
  createdBy: string;
  createdTs: string;
  isEdited: boolean;
  showEditor: boolean;
  isInternal: boolean;
}

export interface FutureDosFormValue {
  futureDosReason: string;
  futureDosComments?: string;
  estimatedDeliveryDt: Date;
}

export interface ButtonArray {
  text: string;
  code?: string;
  icon?: string;
  type?: string;
  look?: string;
  data?: Array<DropDownButtonData>;
  dropdown: boolean;
  dispOrder: number;
  disabled?: boolean;

  callback?<T>(arg?: T): any;
}

export interface FilterButtons {
  filterButtons: Array<ButtonArray>;
  filterButtonsFlatArray: Array<DropDownButtonData>;
  events: Observable<any>;
}


export interface OrderGridList {
  orderId: number;
  payerLobId: number;
  memberId: number;
  orderNum: string;
  statusDesc: string;
  orderStatusCode?: string;
  orderedOn: string;
  payerLobName: string;
  patientName: string;
  orderZipCode: string;
  accepted?: string;
  expected?: string;
  delivered?: string;
  acceptedBy?: string;
  orderDtls?: OrderGridListDtl;
  providerNameLoc?: string;
  authCode?: string;
  trackingNum?: string;
  hcpcCodes: string;
  orderStatusAssignedTS?: string;
  statusReasonDesc?: string;
  statusSubReasonDesc?: string;
  rejectReasonCodeDesc?: string;
  isTransitionOrder: string;
}

export interface OrderGridListDtl {
  createdBy: string;
  insuranceId: string;
  memberName: string;
  items: Array<ItemsGridDtls>;
  recurrences?: Array<OrderGridList>;
  orderSchedule?: OrderSchedule;
}

export interface ItemsGridDtls {
  hcpcCode: string;
  quantity: string;
  authorizationCode: string;
  authWindowFrom: string;
  authWindowTo: string;
}

export interface DropDownButtonData {
  text: string;
  code: string;
  parentBtnId?: number;
  dropdown?: boolean;
  icon?: string;
  nativeType?: ButtonNativeType;
  click: (arg?: any) => any;
}

export interface BtnSkeleton {
  text: string;
  code: string;
  description: string;
  icon: string;
  order: number;
}

export interface ShippedFormValues {
  carrierCode: string;
  carrierName?: string;
  trackingNumber: string;
  shippedTs: Date;
  lateShippedReason?: string;
  isSigRequired: boolean;
  deliveryMethodCode?: string;
}

export interface DeliveredFormValues {
  deliveredTs: Date;
  lateDeliveredReason?: string;
  isSignatureRequired: boolean;
  delvryMethodCode?: string;
}

export interface ApiFilterRequest {
  property: string;
  value: string | string[] | any;
  operator: string;
  group?: number;
}

export interface SearchDataList {
  id: string | any;
  displayName: string;
  displaySelected?: string;
  searchName?: string;
  items?: SearchDataList[];
}

export interface PayerContactFormValues {
  contactId: number;
  contactTypeCode: string;
  contactName: string;
  phoneNumberId?: number;
  phoneNumber?: string;
  faxNumberId?: number;
  faxNumber?: string;
  emailAddressId?: number;
  emailAddress?: string;
}

export interface AccessResolveRequest {
  originService: string;
  loadRoutes: boolean;
  loadWidgets: boolean;
}

export interface PrismCellClickEvent<T> extends CellClickEvent {
  dataItem: T;
}

export interface AuditInfo {
  createdTs: string | Date;
  createdBy: string;
  modifiedTs: string | Date;
  modifiedBy: string;
}

export interface AccessInfo {
  item?: string;
  widget?: string;
  route?: string;
  actions: Array<any>;
}

export interface OrderStatusAttributes {
  code: string;
  value: string;
}

export interface UITransition extends Transition {
  defaultStatusCode?: string;
}

export interface BreadCrumbItemI extends BreadCrumbItem {
  path?: string[];
  state?: {
    [key: string]: any;
  };
}

export interface LookUpListItemDtl {
  listDtlId?: number;
  listTypeId?: number;
  listDtlCode: string;
  listDtlDesc: string;
  listDtlStartDt: string;
  listDtlEndDt?: string | null;
  isReadOnly?: boolean;
  contexts?: Array<ListItemDtlCtx>;
  attributes?: Array<ListItemDtlAttr>;
}

export interface UsersListResponse {
  restartRowNum: string;
  users: UserUI[];
}

export interface UserUI {
  userId?: number;
  firstName: string;
  lastName: string;
  userName: string;
  emailAddress: string;
  phoneNumber?: number | null;
  jobTitle: string;
  userStatusCode: string;
  readonly userTypeCode?: string;
  readonly roleDesc?: string;
}

export interface FAQ {
  question: string;
  answer: string;
}

export interface Media {
  title?: string;
  description: string;
  link: any;
  icon?: string;
  userType: string[];
  hidden?: boolean;
  type?: string;

  callback?(link: string): void;
}

export interface TeamsListResponse {
  restartRowNum: string;
  items: TeamEntry[];
}

export interface TeamModification {
  team: TeamEntry;
  insertTop: boolean;
}

export interface IntakeRuleModification {
  rule: IntakeRuleEntry;
}

export interface IntakeRuleEntry {
  ruleSetName: string;
  effectiveFromDt: string;
  effectiveToDt?: string | null;
  ruleSetStageCode: string;
  decisionKey: number;
  decisionMeta?: string;
  statusCode?: string;
  ruleEntries?: Array<RuleEntry>;
  staticRules: StaticRules;
}

export interface TeamEntry {
  teamId: number;
  teamName: string;
  effectiveFromDt: Date;
  effectiveToDt?: Date | null;
  statusCode?: string;
  isFallback: boolean;
  isEscalation: boolean;
  daysUntilDue: number;
  rules: TeamRuleUI;
  modifiedBy?: string;
  modifiedTs?: Date;
  createdBy?: string;
  createdTs?: string;
}

export interface TeamRuleUI {
  isHighDollar: boolean;
  highDollarAmnt?: number | null;
  isChargeAmnt: boolean;
  chargeAmnt?: number | null;
  isZeroDollar: boolean;
  isSCA: boolean;
  isPayerSentDateNull: boolean;
  hcpcCode: Array<string>;
  hcpcCatg: Array<string>;
  claimDenialCode: Array<string>;
  rejectionCode: Array<string>;
  modifier: Array<string>;
  payerPlans: Array<number>;
  payerLobs: Array<number>;
  correctionCode: string;
  isInPnaq: boolean;
  providerOrgs: Array<number>;
  providerFacs: Array<number>;
  coverageLevel: Array<string>;
  claimStatus: string;
}

export interface ClaimsListResponse {
  restartRowNum: string;
  items: ClaimEntry[];
}

export interface ClaimEntry {
  claimID: number;
  claimNum: string;
  workBasketName?: string;
  ruleSetName?: string;
  claimDoS: string;
  claimDueDt: string;
  claimDetailsURL?: string;
  claimStatusCode?: string;
  claimStatusDesc?: string;
  timelinessCatgCode?: string;
  timelinessCatgDesc?: string;
  mbrName?: string;
  payerFamID: number;
  providerOrgID: number;
  providerFacID: number;
  payerLobID: number;
  payer?: string;
  payerFamilyName?: string;
  payerPlanName?: string;
  payerLobName?: string;
  chargeAmnt: number;
  balanceAmnt: number;
  assignedUserID?: number;
  assignedUserName?: string;
  providerOrgLegalName?: string;
  providerOrgDBAName?: string;
  providerFacilityName?: string;
  claimDtls?: ClaimDtls;
  notes?: Array<ClaimNotes>;
  fifoDecDT?: string | null;
}

export interface ClaimDtls {
  mbrName: string;
  claimItems?: ClaimItemUI[];
  mbrPolicyNumber?: string;
  workBasketName?: string;
  claimRuleSetName?: string;
  timeInActionRequired?: string;
}

export interface ClaimItemUI {
  hcpcCode?: string;
  hcpcDesc?: string;
  denialCodes?: string[];
}

export interface ClaimNotes {
  createdBy: string;
  dateTime: string;
  type: string;
  note: string;
}

export interface TeamUserListResponse {
  restartRowNum: string;
  items: TeamUserUI[];
}

export interface TeamUserUI {
  userId: number;
  userName?: string;
  claimLimit: number;
}

export interface CodeSelection {
  code: string;
  description: string;
}

export interface IntakeRuleResponse {
  restartRowNum: string;
  items: IntakeRule[];
}

export interface IntakeRule {
  ruleSetId: number;
  ruleSetName: string;
  effectiveFromDt: string;
  effectiveToDt?: string | null;
  ruleSetStageCode: string;
  decisionKey: number;
  decisionMeta?: string;
  statusCode?: string;
  createdBy: string;
  createdTs: string;
  modifiedTs: string;
  modifiedBy: string;
}

export interface ChartItem {
  value: number;
  colorField?: string;
}

export class DropDownPagingData {
  data: SearchDataList[] = [];
  filter = '';
  allowLoadMore = true;
  loading = false;
  // default to 1 for backwards compat
  pageSize: number;

  constructor(pageSize: number = 1) {
    this.pageSize = pageSize;
  }

  reset(): void {
    this.filter = '';
    this.allowLoadMore = true;
    this.data = [];
  }

  updateData(data: SearchDataList[], newFilter: string): void {
    this.allowLoadMore = (data?.length || 0) >= this.pageSize;
    if (newFilter !== this.filter || this.data.length === 0) {
      this.data = data;
      this.filter = newFilter;
    } else if (data.length > 0) {
      const ids = this.data.map(item => item.id);
      this.data.push(...data.filter(item => !ids.includes(item.id)));
    }
  }
}

export interface DropDownPageEvent {
  filter: string;
  pageNumber?: number;
  restartRowNumber: number;
  pageSize: number;
}

export class ComboboxFilterEvent {
  filter: string;
  isSelectFilter: boolean;
}

export interface PagingConfig {
  pageSize: number;
  usePageNumberInCallback: boolean;
  onPageCallback?(filter: string, pageIndicator: number, pageSize: number): Observable<any[]>;
}

export interface CheckBoxDtls {
  label: string;
  value: boolean;
  disabled: boolean;
}

export interface RuleEntry {
  ruleFieldId: number;
  ruleEntryValue: string;
  ruleValueEvalParams: string;
  ruleEntryOperator: string;
  ruleEntryValueFieldId?: number;
  isFixed: boolean;
}

export interface StaticRules {
  isPnaq: boolean;
  isBot: boolean;
  isIpr: boolean;
  isPendingAdjustment: boolean;
  isSettlementClaim: boolean;
  isPaidExclusion: boolean;
  canExcludeManualRemark: boolean;
  claimDenialCode?: ClaimRuleCodes;
  payers?: ClaimRulePayers;
}
export interface ReportFilterMeta {
  filterComponent: string;
  formFields: Array<FormFieldConfig>;
}
export interface ResponseModelGeneric<T> {
  list: Array<T> | null;
  count: string | null;
}

export class TreeGridSelectedHierarchy {
  itemType: string;
  id?: number;
  strId?: string;
}

export class TreeGridItem {
  public isExpanded: boolean;
  public children: TreeGridItem[] = [];
  public iconClass?: string;
  public iconImgSrc?: string;
  public iconImgHeight = 12;
  public get selectable(): boolean {
    if (!this.shouldHaveChildren) {
      return true;
    }

    if (this.childrenLoaded) {
      if (!this.children.length) {
        return false;
      }

      if (this.children.every(child => !child.selectable)) {
        return false;
      }

      return true;
    }

    return true;
  }
  public set loadingChildren(value: boolean) {
    this.isLoadingChildren = value;
  }
  public get loadingChildren(): boolean {
    if (this.childrenLoaded || !this.shouldHaveChildren) {
      return false;
    }
    return this.isLoadingChildren;
  }
  private isLoadingChildren = false;
  public childrenLoaded = false;
  constructor(
    public id: string | number,
    public itemType: string,
    public item: any,
    public directParentId?: number | null,
    public parentType?: string,
    private shouldHaveChildren?: boolean) { }

  public get hasChildren(): boolean {
    if (this.children.length > 0) {
      return true;
    }
    return this.shouldHaveChildren;
  }
}

export type FetchChildrenFn = (
  node: TreeGridItem,
  sort: string,
  hierarchy: any[]) => Observable<TreeGridItem[]> | TreeGridItem[];

export interface TreeGridSelections {
  [itemType: string]: (number | string)[];
}

export interface TreeGridSelectableSettings extends SelectableSettings {
  useCheckboxes: boolean;
  emitRowClickEvent?: boolean;
}

export type TreeGridColumnType = 'text' | 'icon-text' | 'pill';
export interface TreeGridColumn {
  field: string;
  title: string;
  type: TreeGridColumnType;
  widthStyle?: {
    width: number;
    widthIsPercent?: boolean;
    bold?: boolean;
  };
  expandable: boolean;
  additionalOptions?: {
    iconClassMap?: Map<string, string>;
    iconImgMap?: Map<string, { src: string, height: number }>;
    [key: string]: any;
  };
}

export class OrganizationContractResponseModel implements ResponseModelGeneric<OrganizationContractModel>
{
  list: Array<OrganizationContractModel>;
  count: string;

  constructor(items: Array<OrganizationContractModel>, count: string) {
    this.list = items;
    this.count = count;
  }
}

export interface OrganizationContractModel {
  contractId?: number;
  contractTypeCd?: string | null;
  contractTypeDescription?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  activeState?: boolean | null;
}

export interface OrganizationAddressModel {
  addressId?: number;
  addressType?: string | null;
  addressTypeCd: string;
  address?: string | null;
  effectiveDate?: string | null;
  expirationDate?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  addressLine3?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
}

export interface SuspendedOrganizationDetails {
  suspensionId: number;
  suspensionType: string;
  active: string;
  startDate: string;
  endDate: string;
  createdByName: string;
  createdDate: string;
  lastModified: string;
  details?: Array<SuspendedOrganizationDetailsByFacilityAndStaff> | null;
}

export interface SuspendedOrganizationDetailsByFacilityAndStaff {
  suspensionId: number;
  facilityId?: number | null;
  staffId?: number | null;
  objectId: number;
  type: string;
  number: string;
  effectiveDate: string;
  expirationDate: string;
  isCorrected: string;
  createdByName: string;
  createdDate: string;
}

export let expireDate: any = {
  field: 'expireDate',
  title: 'EXPIRATION DATE',
  type: 'date',
};
export let expirationDate: any = {
  field: 'expirationDate',
  title: 'EXPIRATION DATE',
  type: 'date',
};
export let effectiveDate: any = {
  field: 'effectiveDate',
  title: 'EFFECTIVE DATE',
  type: 'date',
};
export let createdDate: any = {
  field: 'createdDate',
  title: 'CREATED DATE',
  type: 'text',
};
export let lastModifiedDate: any = {
  field: 'lastModifiedDate',
  title: 'LAST MODIFIED',
  type: 'text',
};
export let defaultPageSizes: PageSizeItem[] = [
  { text: '5', value: 5 },
  { text: '10', value: 10 },
  { text: '15', value: 15 },
  { text: '30', value: 30 },
];

export const fileRestrictions: FileRestrictions = {
  maxFileSize: 5000000,
  allowedExtensions: [
    '.jpg',
    '.png',
    '.pdf',
    '.txt',
    '.doc',
    '.docx',
    '.csv',
    '.ppt',
    '.pptx',
    '.jpeg',
    '.xlsx',
    '.rtf',
    '.bmp',
    '.xls',
  ],
};

export interface BaseFeeScheduleListResponse {
  restartRowNum: string;
  items: BaseFeeSchedsWithVer[];
}

export interface PayerFeeScheduleListResponse {
  restartRowNum: string;
  items: PayerFeeSchedsWithVer[];
}

export interface ProvFeeScheduleListResponse {
  restartRowNum: string;
  items: ProvFeeSchedsWithVer[];
}

export interface BaseFeeSchedVerListResponse {
  restartRowNum: string;
  items: BaseFeeScheduleVers[];
}

export interface PayerFeeSchedVerListResponse {
  restartRowNum: string;
  items: PayerFeeScheduleVer[];
}

export interface ProvFeeSchedVerListResponse {
  restartRowNum: string;
  items: ProvFeeSchedVer[];
}

export interface FeeSchedVerHcpcListResponse {
  restartRowNum: string;
  items: FeeSchedHcpc[];
}

export interface FeeSchedVerCatgListResponse {
  restartRowNum: string;
  items: FeeSchedCatg[];
}

export interface FeeSchedVerEquipTypeListResponse {
  restartRowNum: string;
  items: FeeSchedEquipType[];
}

export interface ProvFeeSchedOrgListResponse {
  restartRowNum: string;
  items: ProvFeeSchedOrg[];
}

export interface PayerRANote {
  payerRemitNoteId: number;
  remitAdvNoteType: string;
  remitAdvNoteTypeDesc: string;
  remitAdvNote: string;
  createdBy: string;
  createdTS: string;
}

export interface RemitAdvClaimListResponse {
  restartRowNum: string;
  items: RemitAdvClaimSummary[];
}

export interface RemitAdvClaimSummary {
  remitAdvClaimId: number;
  remitAdvIssues: boolean;
  claimSrcVerID?: number | null;
  claimIntClaimNum?: string | null;
  claimSrcID?: string | null;
  patient?: string;
  ediPatientName?: string;
  claimPatientName?: string;
  paymentTypeCode?: string;
  paymentTypeDesc?: string;
  paymentStatusCode?: string;
  paymentStatusDesc?: string;
  totalPaidAmnt: number;
  totalSvcChargeAmnt: number;
  totalSvcPaidAmnt: number;
  totalSvcAdjAmnt: number;
  totalInterestAmnt: number;
  createdBy?: string;
  createdTS?: string;
  modifiedBy?: string;
  modifiedTS?: string;
}
