import { Injectable } from '@angular/core';
import { MenuItem, UserMenuItem } from 'src/app/shared/shared-interfaces/shared-interfaces';

@Injectable()
export class MenuService {

  constructor() { }

  public getMenuItems(): MenuItem[] {
    return [{
      text: 'Administration',
      icon: 'k-i-gear',
      expanded: false,
      children: true,
      selected: false,
      level: 0,
      items: [
        {
          text: 'Master Data Management',
          path: '/admin/mdm/list',
          children: false,
          selected: false,
          level: 1,
          widgetId: 'admin/mdm/list'
        },
        {
          text: 'Scheduled Jobs',
          path: '/admin/scheduler/jobs/list',
          children: false,
          selected: false,
          level: 1,
          widgetId: 'admin/scheduler/jobs/list'
        }, {
          text: 'EDI Batches',
          path: '/admin/edi/batches',
          children: false,
          selected: false,
          level: 1,
          widgetId: 'admin/edi/batches'
        }, {
          text: 'LMS Departments',
          path: '/admin/lms/list',
          children: false,
          selected: false,
          level: 1,
          widgetId: 'admin/lms/list'
        }
      ]
    },
    {
      text: 'Members',
      icon: 'k-i-user',
      expanded: false,
      children: true,
      selected: false,
      level: 0,
      items: [
        {
          text: 'View Members',
          path: '/members/list',
          children: false,
          selected: false,
          level: 1,
          widgetId: 'members/list'
        }
      ]
    },
    {
      text: 'Payers',
      icon: 'k-i-paste-plain-text',
      expanded: false,
      children: true,
      selected: false,
      level: 0,
      items: [
        {
          text: 'View Payers',
          path: '/payers/list',
          children: false,
          selected: false,
          level: 1,
          widgetId: 'payers/list'
        }
      ]
    },
    {
      text: 'Payments',
      expanded: false,
      children: true,
      icon: 'k-i-dollar',
      level: 0,
      selected: false,
      featureFlag: 'enable-payment-module',
      items: [
        {
          selected: false,
          text: 'Remittance Advice',
          children: false,
          path: '/payments/remittance/list',
          widgetId: 'payments/remittance/list',
          level: 1,
        },
        {
          selected: false,
          text: 'Deposits',
          children: false,
          path: '/payments/deposits/list',
          widgetId: 'payments/deposits/list',
          level: 1,
        },
        {
          selected: false,
          text: 'Daily Deposit Report',
          children: false,
          path: '/payments/ddr/list',
          widgetId: 'payments/ddr/list',
          level: 1,
        }
      ]
    },
    {
      text: 'Providers',
      icon: 'p-icon-provider-white',
      children: true,
      selected: false,
      expanded: false,
      level: 0,
      isCustom: true,
      items: [
        {
          text: 'View Organizations',
          path: '/providers/organizations',
          children: false,
          selected: false,
          level: 1,
          widgetId: 'providers/organizations'
        },
        {
          text: 'View Facilities',
          path: '/providers/facilities',
          children: false,
          selected: false,
          level: 1,
          widgetId: 'providers/facilities'
        },
        {
          text: 'View Staff',
          path: '/providers/staffs',
          children: false,
          selected: false,
          level: 1,
          widgetId: 'providers/staffs'
        },
        {
          text: 'View Compliance Documents',
          path: '/providers/compliancedocuments',
          children: false,
          selected: false,
          level: 1,
          widgetId: 'providers/organizations'
        }
      ]
    },
    {
      text: 'Orders',
      icon: 'k-i-copy',
      expanded: false,
      children: true,
      selected: false,
      level: 0,
      items: [
        {
          text: 'Order Management',
          children: false,
          selected: false,
          path: '/orders/list',
          level: 1,
          widgetId: 'orders/list',
        },
        {
          text: 'Order Routing Tool',
          children: false,
          selected: false,
          path: '/orders/ort',
          level: 1,
          widgetId: 'orders/ort'
        },
        {
          text: 'Bulk Order Uploads',
          children: false,
          selected: false,
          path: '/uploads/payers/orders',
          level: 1,
          widgetId: 'uploads/payers/orders',
        },
        {
          text: 'Transition Campaigns',
          children: false,
          selected: false,
          path: '/orders/transitions/list',
          level: 1,
          widgetId: 'orders/transitions/list',
        }
      ]
    },
    {
      text: 'Fee Schedule',
      icon: 'k-i-calendar-date',
      expanded: false,
      children: true,
      selected: false,
      level: 0,
      featureFlag: 'PQ-70-enable-fee-schedule',
      items: [
        {
          text: 'Base Fee Schedule',
          children: false,
          selected: false,
          path: '/feeschedules/base/list',
          level: 1,
          widgetId: 'feeschedules/base/list'
        },
        {
          text: 'Payer Fee Schedule',
          children: false,
          selected: false,
          path: '/feeschedules/payer/list',
          level: 1,
          widgetId: 'feeschedules/payer/list'
        },
        {
          text: 'Provider Fee Schedule',
          children: false,
          selected: false,
          path: '/feeschedules/provider/list',
          level: 1,
          widgetId: 'feeschedules/provider/list'
        }
      ]
    },
    {
      text: 'Claims',
      icon: 'k-i-paste-plain-text',
      expanded: false,
      children: true,
      selected: false,
      level: 0,
      items: [
        {
          text: 'My Claims Que',
          path: '/mcq/claims/list',
          children: false,
          selected: false,
          level: 1,
          widgetId: 'mcq/claims/list'
        },
        {
          text: 'MCQ Rules',
          path: 'mcq/intake/rules/list',
          children: false,
          selected: false,
          level: 1,
          widgetId: 'mcq/intake/rules/list'
        },
        {
          text: 'Claims Routing',
          path: '/mcq/workbasket/list',
          children: false,
          selected: false,
          level: 1,
          widgetId: 'mcq/workbasket/list'
        }
      ]
    },
    {
      text: 'Reports',
      icon: 'k-i-subreport',
      children: true,
      selected: false,
      expanded: false,
      path: '/reports/summary',
      level: 0,
      widgetId: 'reports/summary',
      items: [
        {
          text: 'View Reports',
          path: '/reports/summary',
          children: false,
          selected: false,
          level: 1,
          widgetId: 'reports/summary'
        }
      ]
    }
    ];
  }

  public getUserMenuItems(name: string): UserMenuItem[] {
    return [{
      text: 'userHeader',
      items: [
        {
          text: name
        },
        {
          text: 'Logout',
          action: 'logoutFromApp'
        }
      ]
    }
  ];
  }
}
