import { Component, Input } from '@angular/core';
import { ModalAction } from '../../shared-interfaces';

@Component({
  selector: 'prism-modal-bottom',
  templateUrl: './modal-bottom.component.html',
  styleUrls: ['./modal-bottom.component.scss']
})
export class ModalBottomComponent {
  @Input() title: string;
  @Input() titleIcon: string;
  @Input() titleIconType = '';
  @Input() opened: boolean;
  @Input() headerActions: ModalAction[];
  @Input() actionsAlign: 'end' | 'between' | 'start' = 'end';
  @Input() useTitleBar = true;
  constructor() { }
}
